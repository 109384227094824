import React from "react";

import "./App.css";
import PalestinianFlag from "./components/free-palestine/Free-Palestine";

function App() {
  return (
    <div id="container">
      <main>
        <PalestinianFlag />
      </main>
    </div>
  );
}

export default App;

import "./Free-Palestine.css";

function PalestinianFlag() {
  return (
    <section className="free-palestine">
      <div className="flag">
        <div className="stripe black"></div>
        <div className="stripe white">
          <div className="space-holder"></div>
          <h1>Free Palestine</h1>
        </div>
        <div className="stripe green"></div>
        <div className="triangle red"></div>
      </div>
    </section>
  );
}

export default PalestinianFlag;
